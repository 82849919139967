const env = process.env.NODE_ENV;
const mode = process.env.VUE_APP_MODE;
console.log(999999, process.env);
export default function getConstants() {
    if (mode === 'test' || env === 'development') {
        return {
            serverLink: 'https://testservice.fivexw.com',
            swapLink: 'https://testswap.fivexw.com',
            wsUri: 'wss://testservice.fivexw.com/api/fv2-game-ws',
            FomoPkAddress: '0xc973b8B9B30CC0A0EfF7CFCC6162bB7f558db1E5',
            FomoActionAddress: '0x071Efeb02336588F423e1a0f402008546a81867A',
            TokenExchangeAddress: '0x42dC41CE89f025c83E08083F37571B6d933e8371',
            blankAddr: '0x0000000000000000000000000000000000000000',
            V1BaseUrl: 'https://v1.fivexw.com/#/selectMode',
            TokensOptions: {
                '0x0B6F8848fCA58031fd612dDfc26ABA460A4929aE': {
                    name: 'AURA',
                    address: '0x0B6F8848fCA58031fd612dDfc26ABA460A4929aE',
                    decimal: 18,
                    unitDecimal: 0,
                    unitName: '',
                    tokenName:'灵石'
                },
                // '0xCafA482773481435ACb227A5a08e6B4f49CD88Ce': {
                //     name: 'HTMoon2',
                //     address: '0xCafA482773481435ACb227A5a08e6B4f49CD88Ce',
                //     decimal: 18,
                //     unitDecimal: 8,
                //     unitName: '亿',
                //     tokenName:'火月'
                // },
                // '0x36E5d9e3Bb9498ac47B8957Dc8d213Ec126B18C1': {
                //     name: 'HJW',
                //     address: '0x36E5d9e3Bb9498ac47B8957Dc8d213Ec126B18C1',
                //     decimal: 18,
                //     unitDecimal: 0,
                //     unitName: '',
                //     tokenName:'精金'
                // }
            }
        };
    } else if (env === 'production') {
        return {
            serverLink: 'https://service.fivexw.com',
            swapLink: 'https://hecoswap.5swap.io',
            wsUri: 'wss://service.fivexw.com/api/fv2-game-ws',
            FomoPkAddress: '0xA33E78500aE0202d2afeCd3b68F574a2d53934DC',
            FomoActionAddress: '0xfdB223C0dfba93423abcfAF0A365581aD4Fb53B2',
            TokenExchangeAddress: '0xB1093E94C0F423e90b0c222551a428114250A174',
            blankAddr: '0x0000000000000000000000000000000000000000',
            V1BaseUrl: 'https://v1.fivexw.com/',
            TokensOptions: {
                '0x745e5A2f4dB6C78C71911E86E5978990a3630666': {
                    name: 'AURA',
                    address: '0x745e5A2f4dB6C78C71911E86E5978990a3630666',
                    decimal: 18,
                    unitDecimal: 0,
                    unitName: '',
                    tokenName:'灵石'
                },
                // '0x2f1805CD2bBc416492890ae7fE123B50Cd15F53F': {
                //     name: 'HTMoon2',
                //     address: '0x2f1805CD2bBc416492890ae7fE123B50Cd15F53F',
                //     decimal: 18,
                //     unitDecimal: 8,
                //     unitName: '亿',
                //     tokenName:'火月'
                // },
                // '0x96B414744296E0027B3f75557936d4BFFd5eb810': {
                //     name: 'HJW',
                //     address: '0x96B414744296E0027B3f75557936d4BFFd5eb810',
                //     decimal: 18,
                //     unitDecimal: 0,
                //     unitName: '',
                //     tokenName:'精金'
                // }
            }
        };
    } else {
        console.log('ENV:', env);
        return null;
    }
}
