import { getAccount, getErc20Contract, isLoading } from '../utils';
import Decimal from 'decimal.js';
import { ElMessage } from 'element-plus';
import config from '../store/config';

export const approve = async (ownerAddr, spenderAddr, value) => {
    // ownerAddr: TokenAddr 0x0B6F8848fCA58031fd612dDfc26ABA460A4929aE
    // spenderAddr: pkAddr 0xC5131A09cEa71682Aa66104fA19263807D41B53e
    isLoading(true);
    const account = await getAccount();
    const allowance = await getErc20Contract(ownerAddr)
        .methods.allowance(account, spenderAddr)
        .call();
    if (Number(allowance) < Number(value)) {
        try {
            await getErc20Contract(ownerAddr)
                .methods.approve(
                    spenderAddr,
                    new Decimal(value).mul(new Decimal(10).pow(5)).toFixed()
                ) //100000倍当前选项 token allowance
                .send({ from: account, gasPrice: config.GAS_PRICE});
            ElMessage.success({
                message: '授权成功',
                type: 'success'
            });
            return 'success';
        } catch (e) {
            console.error(e);
            ElMessage.warning({
                message: '授权失败',
                type: 'warning'
            });
            return null;
        } finally {
            isLoading(false);
        }
    }
    isLoading(false);
};
