<template>
  <div class="create-union">
    <div class="item choose-icon">
      <span class="title">公会队标</span>
      <img
        :src="headInfo.iconUrl"
        alt="icon"
        v-if="headInfo.iconUrl"
        style="width: 0.3rem; vertical-align: middle"
      />
      <span v-else>请选择公会图标</span>
    </div>
    <div class="item">
      <span class="title">名称</span>
      <input
        type="text"
        class="input"
        v-model="headInfo.name"
        disabled
        placeholder="请输入公会名称"
      />
    </div>

    <div class="item">
      <span class="title">质押门槛</span>
      <el-input-number
        v-model="headInfo.cill"
        :controls="false"
        controls-position="right"
        disabled
        placeholder="请输入会员质押门槛"
        :min="1"
      ></el-input-number>
      <!-- <input type="text" class="input" /> -->
    </div>

    <div class="item">
      <span class="title">星级</span>
      <div class="stars">
        <div :class="{ chk: headInfo.level >= 1 ? true : false }"></div>
        <div
          :class="{ chk: headInfo.level >= 2 ? true : false }"
          v-if="headInfo.level >= 2"
        ></div>
        <div
          :class="{ chk: headInfo.level >= 3 ? true : false }"
          v-if="headInfo.level >= 3"
        ></div>
        <div
          :class="{ chk: headInfo.level >= 4 ? true : false }"
          v-if="headInfo.level >= 4"
        ></div>
        <div
          :class="{ chk: headInfo.level >= 5 ? true : false }"
          v-if="headInfo.level >= 5"
        ></div>
        <!-- <div
          :class="{ chk: level >= 4 ? true : false }"
          @click="chkLevel(4)"
        ></div>
        <div
          :class="{ chk: level >= 5 ? true : false }"
          @click="chkLevel(5)"
        ></div>  -->
      </div>
    </div>
    <div class="item">
      <span class="title">门派</span>
      <input
        type="text"
        class="input"
        v-model="headInfo.faction"
        disabled
        placeholder="请选择你的门派"
      />
    </div>
    <div class="item flex">
      <span>当前可用圣物：{{ currentGoods }}</span>
      <span class="fixButton" @click="goJh"> 合成 </span>
      <!-- <input type="text" class="input" /> -->
    </div>
    <div class="item" style="height: 0.8rem">
      <span class="title">质押数量</span>
      <el-input-number
        v-model="Threshold"
        :controls="false"
        controls-position="right"
        placeholder="请输入需要质押的数量"
      ></el-input-number>
      <p style="font-size: 0.08rem; line-height: 0.08rem; color: grey">
        Tips：你质押的数量越多，占领矿山后按比例得到的收益也越多
      </p>
      <!-- <input type="text" class="input" /> -->
    </div>
    <div class="activity">
      <p class="title">工会活跃度</p>
      <p class="content">
        <span>总活跃度：{{ headInfo.active }}</span>
        <span class="right">当日活跃度：{{ headInfo.activeDay }}</span>
      </p>
    </div>
    <p class="pledge">
      <span>加入公会最低质押数量：</span
      ><span class="number"
        >{{ headInfo.cill }}{{ getPriceName(headInfo.relicToken) }}</span
      >
    </p>
    <button @click="joinUnion">加入公会</button>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { watch } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { getUnionInfoHead, getPlayeMoney, canJoin } from "../apis/httpReq";
import {
  getAccount,
  addDecimal,
  isLoading,
  formatTokenUnit,
  getActionContract,
} from "../utils";
import { createUnionCall } from "../apis/callBack";
import getToken from "../store/constants";
import config from"../store/config";
export default {
  name: "CreateUnion",
  async setup() {
    const router = useRouter();
    const store = useStore();

    let level = ref(1);
    let token = ref("");
    let unionNum = ref(1);
    let token_name = ref("");
    let unionName = ref("");
    let MineM = ref("");
    let unionIcon = ref(""); //选择公会图标返回的图标
    let Threshold = ref("");
    let currentGoods = ref(0);

    let account = await getAccount();
    let playerMoney;
    let price_name = ref("");
    let unionId;
    let actionContract = getActionContract();

    unionId = ref(router.currentRoute.value.query.unionId);

    playerMoney = ref(await getPlayeMoney(account));
    let headInfo = ref(await getUnionInfoHead(unionId.value));
    playerMoney.value.forEach((v) => {
      if (v.token_name == headInfo.value.token) {
        currentGoods.value = v.amount;
      }
    });
    watch(
      () => router.currentRoute.value.query.unionId,
      async () => {
        if (router.currentRoute.value.path == "/joinunion") {
          unionId.value = router.currentRoute.value.query.unionId;
          playerMoney.value = await getPlayeMoney(account);
          headInfo.value = await getUnionInfoHead(unionId.value);
          playerMoney.value.forEach((v) => {
            if (v.token_name == headInfo.value.token) {
              currentGoods.value = v.amount;
            }
          });
        }
      }
    );
    const getPriceName = (item) => {
      let name;
      Object.values(getToken().TokensOptions).forEach((v) => {
        if (v.name == item.replace("_RELIC", "")) {
          name = v.tokenName;
        }
      });
      return name;
    };

    const joinUnion = () => {
      //加入公会
      playerMoney.value.forEach(async (v) => {
        if (v.token_name == headInfo.value.token) {
          if (Threshold.value > v.amount) {
            ElMessage.error({
              message: "你的质押数不足请先合成相应的圣物",
            });
            return;
          } else if (Threshold.value < headInfo.value.cill) {
            ElMessage.error({
              message:
                "你的质押数未达到标准，要求" + headInfo.value.cill + "个",
            });
            return;
          } else if (Threshold.value < 0 || !Threshold.value) {
            ElMessage.error({
              message: "质押数不能为负或者为空",
            });
            return;
          }
          // console.log(Threshold.value)
          // return
          isLoading(true);
          actionContract.methods
            .requestJoinGuild(Number(unionId.value), Threshold.value)
            .send({ from: await getAccount(), gasPrice: config.GAS_PRICE })
            .catch((err) => {
              ElMessage.warning({
                message: "用户取消",
                type: "warning",
              });
              isLoading(false);
            })
            .then((res) => {
              if (!store.state.createUnionNow) {
                let msg = createUnionCall(res.transactionHash).msg;
                ElMessage.success({
                  message: msg || "加入成功",
                  type: "success",
                });
                router.push({
                  path: "./unionlist",
                });
                isLoading(false);
                store.state.createUnionNow = false;
              }
            });
        }
      });
    };
    const goJh = () => {
      router.push({ path: "./jh", query: { menpai: headInfo.value.faction } });
    };

    return {
      level,
      getPriceName,
      goJh,
      Threshold,
      addDecimal,
      headInfo,
      currentGoods,
      unionNum,
      MineM,
      unionIcon,
      token,
      price_name,
      unionName,
      joinUnion,
      formatTokenUnit,
    };
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item {
}
::v-deep .el-input__inner {
  border: none;
  padding-top: 0.06rem;
}
input {
  border: none;
  padding-top: 0.06rem;
}
.activity {
  background: #fff;
  margin-top: 0.02rem;
  text-align: left;
  padding: 0.17rem;
  overflow: hidden;
  .title {
    // @include bold();
    font-size: 0.15rem;
    margin-bottom: 0.12rem;
    &::after {
      content: "";
      display: inline-block;
      height: 0.18rem;
      width: 0.15rem;
      background: url("../assets/images/fire.png") 100% / cover;
      margin: 0 0 -0.03rem 0.04rem;
    }
  }

  .content {
    font-size: 0.13rem;
    .right {
      float: right;
    }
  }
}
.fixButton {
  background: linear-gradient(180deg, #fc7dcd 0%, #f7479e 98%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 2px;
  font-size: 0.12rem;
  height: 0.2rem;
  padding: 0 0.06rem;
}
</style>
